import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Wrapped in squashy silk blankets she lays,`}<br parentName="p"></br>{`
`}{`With her lithe body unwilling to raise.`}<br parentName="p"></br>{`
`}{`Fragrant breakfast conveyed directly to her jumbo bed,`}<br parentName="p"></br>{`
`}{`By the servants of her own, who keep their envy unsaid.`}<br parentName="p"></br>{`
`}{`Like birds they wirl all over her precious chamber,`}<br parentName="p"></br>{`
`}{`Adorning their hirer and curbing their anger.`}</p>
    <p>{`Her Louboutin heels rap the marble ground as she marches,`}<br parentName="p"></br>{`
`}{`While she is giving out unpleasant and foolish charges.`}<br parentName="p"></br>{`
`}{`Chanel LBD binded firm on her lissom silhouette,`}<br parentName="p"></br>{`
`}{`Her plumpy lips protracting smoke from a lavish cigarette.`}<br parentName="p"></br>{`
`}{`All men glance when she rambles across bustling streets,`}<br parentName="p"></br>{`
`}{`To which she grumply threatens to arouse police.`}</p>
    <p>{`Driving her Lamborghini Aventador S she gets delayed by the cops,`}<br parentName="p"></br>{`
`}{`She gives them long-awaited thrills and bills in a box.`}<br parentName="p"></br>{`
`}{`Reluctant to wait, she speeds through the track,`}<br parentName="p"></br>{`
`}{`Other drivers judge her, to what she says “Hope your cars crack”.`}<br parentName="p"></br>{`
`}{`Her servants dash back to work when “her majesty” arrives,`}<br parentName="p"></br>{`
`}{`Afraid of receiving madam’s disguise.`}</p>
    <p>{`Soon she lays in a jacuzzi, with pastel petals flowing all around,`}<br parentName="p"></br>{`
`}{`Her narrow waist burning from the taut corset bound.`}<br parentName="p"></br>{`
`}{`Toy-alike feet are all slashed by the poky heels she daily wears,`}<br parentName="p"></br>{`
`}{`Slim fingers are crippled from the massive gold rings she wears in pairs.`}<br parentName="p"></br>{`
`}{`Memories sneak out of her eyes and streak down her cheeks,`}<br parentName="p"></br>{`
`}{`She recalls the times when she was healthy and there were no addressed critiques.`}</p>
    <p>{`She calmly plots towards her gigantic snuffy bed,`}<br parentName="p"></br>{`
`}{`She then reposes with her eyes weeping, feeling extremely sad.`}<br parentName="p"></br>{`
`}{`“That terrible passion, the horrible sense of depression,`}<br parentName="p"></br>{`
`}{`Why do humans have that much aggression?” - she whispers in oppression.`}<br parentName="p"></br>{`
`}{`Her wounded soul howls through her mind-blowing body,`}<br parentName="p"></br>{`
`}{`But there’s no chance her passion will be forgotten so she drinks a toddy.`}</p>
    <p>{`Weary lady than rises and flashes to her magnificent veranda,`}<br parentName="p"></br>{`
`}{`Precious cigarettes vanish from their pack while she listens to banda.`}<br parentName="p"></br>{`
`}{`She won’t sleep as there is no need,`}<br parentName="p"></br>{`
`}{`Tones of tone, face powder, mascara and others will fix her, indeed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      